export function useArticleReadCounts() {
  const { time } = useServerTiming();

  return useQuery({
    queryKey: ["article-counts"],
    staleTime: 1000 * 60 * 60,
    queryFn: async () => {
      const res = await time("GetArticleReadCounts", () =>
        GqlGetArticleCounts({
          language: "no-nb",
        }));

      return {
        id: res.catalogue?.id,
        counts: toReadCounts(res),
      };
    },
  });
}
